import React, { Component } from 'react';
import Highcharts from "highcharts";
import PropTypes from 'prop-types';

import { MainGraph, AVGContainer, ContainerInsideChart } from './styles';
// eslint-disable-next-line no-undef
require('highcharts/highcharts-more')(Highcharts);
// eslint-disable-next-line no-undef
require('highcharts/modules/solid-gauge')(Highcharts);

class HighchartComponent extends Component {

  componentDidMount() {
    const { payload, id } = this.props;
    new Highcharts.chart({
      chart: {
        type: 'solidgauge',
        renderTo: id,
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.0)'
      },

      title: null,

      credits: {
        enabled: false
      },

      tooltip: {
        enabled: false
      },

      pane: {
        startAngle: -160,
        endAngle: 160,
        background: [{
          outerRadius: '112%',
          innerRadius: '103%',
          backgroundColor: '#E9E9E9' || Highcharts.Color(Highcharts.getOptions().colors[0])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
          shape: "arc"
        }],
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: parseFloat(payload.percent_calories) > 0
        }
      },

      series: [{
        name: 'Move',
        data: [{
          color: '#ff7e19',
          radius: '112%',
          innerRadius: '103%',
          y: parseFloat(payload.percent_calories)
        }],
      }]
    });
  }

  render() {
    const { id, payload} = this.props;

    return (
      <MainGraph>
        <div id={id} />
        <AVGContainer>BURNED</AVGContainer>
        <ContainerInsideChart calories={parseFloat(payload && payload.calories)}>
          {payload ? parseFloat(payload.calories) : null}
          <p>Calories</p>
        </ContainerInsideChart>
      </MainGraph>
    )
  }
}

HighchartComponent.propTypes = {
  id: PropTypes.string,
  payload: PropTypes.object
};

export default HighchartComponent;
