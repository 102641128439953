import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HighchartComponent from './Highchart';

import { NavigationBox, DailyFitnessActivityHeader, Content, StepAndDistance, CaloriesBurned,  CurrentTime, DateRightArrow, LeftArrow, MyDailyActivityContainer, ContentWrapper} from './styles';

import { imgPath } from '../../../utils/constants';
import { commaSeperator } from '../../../utils/methods';
import InputGoals from '../../InputGoals';
import moment from "moment";
import {getDailyActivityOverview, getMonthlyActivityOverview} from "../../../redux/actions/wellnessDashboardActions";
import {connect} from "react-redux";
import {LoaderContainer} from "../../Content/styles";
import {ContainerV2} from "../../ContentV2/styles";
import Loading from "../../Loading";
import Waiting from "../../Waiting";
import {withTranslation} from 'react-i18next';
const monthlyGoalsData = [
  {text: "Total Steps", key: "monthly_steps"},
  {text: "Calories Burned", key: "monthly_calories"},
  {text: "Total Distance", key: "monthly_distance"}
];
class DailyFitnessActivity extends Component {
  constructor() {
    super();
    this.state = {
      showInputGoalsModal: false,
      modalName: '',
      date: new Date()
    };
  }
  componentDidMount() {
    const { date } = this.state;
    const{challenge, getMonthlyActivityOverview} = this.props;
    const monthStartDate = challenge && moment(date).startOf('month').format('YYYY-MM-DD');
    challenge ? getMonthlyActivityOverview(monthStartDate) : this.getActivityOverviewData(this.getStartDate(date, 'day'), this.getEndDate(date, 'day'));
  }
  showModal = (name) => {
    this.setState({
      modalName: name,
    });
  };

  hideModal = () => {
    this.setState({
      modalName: ''
    });
  };
  getStartDate = (date, value) => this.props.challenge ? moment(date).startOf(value).format('YYYY-MM-DD') : moment(date).startOf(value).format('MM-DD-YYYY');

  getEndDate = (date, value) => moment(date).endOf(value).format('MM-DD-YYYY');

  getActivityOverviewData = (startDate, endDate) => {
    this.props.fetchActivityOverview(startDate, endDate)
  };

  changeToPreviousValue = (value) => {
    const { date } = this.state;
    const {challenge, getMonthlyActivityOverview} = this.props;
    this.setState({
      date: moment(date).subtract(1, value)
    }, () => {
      challenge ? getMonthlyActivityOverview(this.getStartDate(this.state.date, value)) : this.getActivityOverviewData(this.getStartDate(this.state.date, value), this.getEndDate(this.state.date, value));
    });
  };

  changeToNextValue = (value) => {
    const { date } = this.state;
    const {challenge, getMonthlyActivityOverview} = this.props;
    this.setState({
      date: moment(date).add(1, value)
    }, () => {
      challenge ? getMonthlyActivityOverview(this.getStartDate(this.state.date, value)) :  this.getActivityOverviewData(this.getStartDate(this.state.date, value), this.getEndDate(this.state.date, value));
    });
  };
  render() {
    const { modalName, date} = this.state;
    const {SetDailyGoal, inputGoals, activityOverview, activityLoading, challenge, monthlyActivityOverview,t} = this.props;
    const disableRightArrow = moment(date).startOf(challenge ? 'month' : 'day') >= moment().startOf(challenge ? 'month' : 'day');
    const setWaiting = challenge ? !monthlyActivityOverview : !activityOverview;
    if(setWaiting){
      return <Waiting />;
    }
    const monthlyGoals = challenge && monthlyActivityOverview['monthly_goals'];
    const WorkoutData = challenge && monthlyActivityOverview['workout_data'];
    return (
      <ContainerV2 height={challenge ? '315px' : '240px'} width={challenge ? '100%' : 'calc(62% - 15px)'}>
        <NavigationBox isDailyFitnessActivity>
          <DailyFitnessActivityHeader>
            {/*<div>
              <div className="title">Daily Fitness Activity</div>
              <div className="goalSetting" goals onClick={() => this.showModal('inputGoal')}>
                <span>Goal Setting</span>
                <RightArrow className="fas fa-angle-right"/>
              </div>
            </div>*/}
            <CurrentTime>
              <LeftArrow onClick={() => this.changeToPreviousValue(challenge ? 'month' : 'day')} />
              <div>
                <div className="title">{challenge ? 'My Monthly Average' : 'Daily Fitness Activity'}</div>
                <div className="Date">{challenge ? `${moment(date).startOf('Month').format('MMMM, YYYY')}`: moment(date).startOf('day').format('Do MMMM, YYYY')}</div>
              </div>
              <DateRightArrow onClick={() => this.changeToNextValue(challenge ? 'month' : 'day')} disable={disableRightArrow} />
            </CurrentTime>
          </DailyFitnessActivityHeader>
          {activityLoading ? <LoaderContainer><Loading/></LoaderContainer> :
            <ContentWrapper>
              {
                <Content>
                  <StepAndDistance left>
                    <img src={`${imgPath}/NewDashboardImages/Footprint.png`} alt="Footprint"/>
                    <div
                      className="StepsCount">{challenge ? WorkoutData && commaSeperator(parseInt(WorkoutData['steps'] || 0)) : activityOverview && commaSeperator(parseInt(activityOverview['steps'] || 0))}</div>
                    <div className="TotalSteps">Steps</div>
                  </StepAndDistance>
                  <CaloriesBurned>
                    <HighchartComponent id="graph" payload={challenge ? WorkoutData : activityOverview}/>
                  </CaloriesBurned>
                  <StepAndDistance right>
                    <img style={{width:"27px", height:"37px"}} src={`${imgPath}/NewDashboardImages/distanceNew.png`} alt="Distance"/>
                    <div
                      className="StepsCount">{challenge ? WorkoutData && `${parseFloat(WorkoutData['distance'] || 0.00).toFixed(1)} miles` : activityOverview && `${parseFloat(activityOverview['distance'] || 0.00).toFixed(1)} miles`}</div>
                    <div className="TotalSteps">Miles</div>
                  </StepAndDistance>
                </Content>
              }
              {
                challenge ? <MyDailyActivityContainer>
                  <div>
                    {t('My Daily Activity Goals')}
                  </div>
                  <div>
                    {monthlyGoalsData.map((data, index) => (<div key={index}>
                      <div>
                        {data.text === 'Total Distance' ? `${monthlyGoals[data.key]} mi` : commaSeperator(parseInt(monthlyGoals[data.key]))}
                      </div>
                      <div>
                        {data.text}
                      </div>
                    </div>))
                    }
                  </div>
                </MyDailyActivityContainer> : null
              }
            </ContentWrapper>
          }
        </NavigationBox>
        <InputGoals SetDailyGoal={SetDailyGoal} showModal={modalName === 'inputGoal'} onHide={this.hideModal} inputGoals={inputGoals} />
      </ContainerV2>
    )
  }
}

DailyFitnessActivity.propTypes = {
  SetDailyGoal: PropTypes.func,
  inputGoals: PropTypes.object,
  activityOverview: PropTypes.object,
  activityLoading: PropTypes.bool.isRequired,
  fetchActivityOverview: PropTypes.func.isRequired,
  challenge: PropTypes.number,
  getMonthlyActivityOverview: PropTypes.func,
  monthlyActivityOverview: PropTypes.object,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  activityOverview: state.wellnessDashboard.dailyActivityOverview,
  activityLoading: state.wellnessDashboard.activityLoading,
  monthlyActivityOverview: state.wellnessDashboard.monthlyActivityOverview
});

const mapDispatchToProps = (dispatch) => ({
  fetchActivityOverview: (startDate, endDate) => dispatch(getDailyActivityOverview(startDate, endDate)),
  getMonthlyActivityOverview: (date) => dispatch(getMonthlyActivityOverview(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DailyFitnessActivity));
