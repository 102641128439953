import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDailyActivityOverview, getMonthlyActivityOverview } from "../../../redux/actions/wellnessDashboardActions";
import { connect } from "react-redux";
import { Tab } from "react-bootstrap";
import {
  DailyActivityContainer, NavigationBox, DailyFitnessActivityHeader,
  CurrentTime, LeftArrow, DateRightArrow, ContainerV3, ContentWrapper, Content, CaloriesBurned, SmallHighChartContainer,
} from './styles';
import { LoaderContainer } from "../../Content/styles";
import moment from "moment";
import Loading from "../../Loading";
import Waiting from "../../Waiting";
import HighChartV3 from './HighChartV3';
import SmallHighCharts from './SmallHighchartsDailyActivity/SmallHighCharts';
import InputGoalsV2 from '../../InputGoalsV2';
import _ from 'lodash';

const IdForHighChart = [
  "calories",
  "location"
]

const ActivityKeys = [
  "calories",
  "location",
  "time"
]

class DailyFitnessActivityV3 extends Component {
  constructor() {
    super();
    this.state = {
      showInputGoalsModal: false,
      modalName: '',
      date: new Date(),
      showModal: '',
      ShowCompleteFitnessUI: false
    };
  }
  componentDidMount() {
    const { date } = this.state;
    const { challenge, getMonthlyActivityOverview } = this.props;
    const monthStartDate = challenge && moment(date).startOf('month').format('YYYY-MM-DD');
    challenge ? getMonthlyActivityOverview(monthStartDate) : this.getActivityOverviewData(this.getStartDate(date, 'day'), this.getEndDate(date, 'day'));
  }
  showModal = (name) => {
    this.setState({
      modalName: name,
    });
  };

  hideModal = () => {
    this.setState({
      modalName: ''
    });
  };
  getStartDate = (date, value) => this.props.challenge ? moment(date).startOf(value).format('YYYY-MM-DD') : moment(date).startOf(value).format('MM-DD-YYYY');

  getEndDate = (date, value) => moment(date).endOf(value).format('MM-DD-YYYY');

  getActivityOverviewData = (startDate, endDate) => {
    this.props.fetchActivityOverview(startDate, endDate)
  };

  changeToPreviousValue = (value) => {
    const { date } = this.state;
    const { challenge, getMonthlyActivityOverview } = this.props;
    this.setState({
      date: moment(date).subtract(1, value)
    }, () => {
      challenge ? getMonthlyActivityOverview(this.getStartDate(this.state.date, value)) : this.getActivityOverviewData(this.getStartDate(this.state.date, value), this.getEndDate(this.state.date, value));
    });
  };

  changeToNextValue = (value) => {
    const { date } = this.state;
    const { challenge, getMonthlyActivityOverview } = this.props;
    this.setState({
      date: moment(date).add(1, value)
    }, () => {
      challenge ? getMonthlyActivityOverview(this.getStartDate(this.state.date, value)) : this.getActivityOverviewData(this.getStartDate(this.state.date, value), this.getEndDate(this.state.date, value));
    });
  };

  updatedFitnessGoals = () => {
    this.setState({ ShowCompleteFitnessUI: true });
  };

  render() {
    const { modalName, date } = this.state;
    const { SetDailyGoal, inputGoals, activityOverview, activityLoading, challenge, monthlyActivityOverview, user, month, check } = this.props;
    const disableRightArrow = moment(date).startOf(challenge ? 'month' : 'day') >= moment().startOf(challenge ? 'month' : 'day');
    const setWaiting = challenge ? !monthlyActivityOverview : !activityOverview;
    if (setWaiting) {
      return <Waiting />;
    }
    const WorkoutData = challenge && monthlyActivityOverview['workout_data'];
    return (
      <DailyActivityContainer>
        {/* <StyledTabContainer id="Tabs"> */}
        <div>
          {"Daily Activity"}
        </div>
        <Tab eventKey={1} /*title="Daily Activity"*/ tabClassName="first-tab" id="first-tab">
          {!_.isEmpty(challenge) || !_.isUndefined(challenge) || !_.isEmpty(WorkoutData) || !_.isUndefined(WorkoutData) || !_.isEmpty(activityOverview) || !_.isUndefined(activityOverview)
            ?
            <ContainerV3 height={challenge ? '315px' : '374px'} width={'100%'} marginTop={1}>
              <NavigationBox isDailyFitnessActivity radius={1}>
                <DailyFitnessActivityHeader>
                  <CurrentTime marginTop={'20px'}>
                    <LeftArrow onClick={() => this.changeToPreviousValue(challenge ? 'month' : 'day')} />
                    <div>
                      <div className="Date">{challenge ? `${moment(date).startOf('Month').format('MMMM, YYYY')}` : moment(date).startOf('day').format('Do MMMM, YYYY')}</div>
                    </div>
                    <DateRightArrow onClick={() => this.changeToNextValue(challenge ? 'month' : 'day')} disable={disableRightArrow} />
                  </CurrentTime>
                </DailyFitnessActivityHeader>
                {activityLoading ? <LoaderContainer><Loading /></LoaderContainer> :
                  <ContentWrapper>
                    {
                      <Content>
                        <CaloriesBurned>
                          <HighChartV3 id="graph" payload={challenge ? WorkoutData : activityOverview} check={check} />
                        </CaloriesBurned>
                      </Content>
                    }
                  </ContentWrapper>
                }
                <SmallHighChartContainer>
                  {IdForHighChart.map((ID, index) => (
                    <SmallHighCharts id={ActivityKeys[index]} payload={challenge ? WorkoutData : activityOverview} key={ID} highChartID={ID} check={check} />
                  ))}
                </SmallHighChartContainer>
              </NavigationBox>
            </ContainerV3>
            :
            <LoaderContainer><Loading /></LoaderContainer>
          }
        </Tab>
        {/* <Tab eventKey={2} title="Fitness Goal" tabClassName="second-tab" id="second-tab">
            { !_.isEmpty(monthlyGoal) || !_.isUndefined(monthlyGoal) || !_.isNull(monthlyGoal)
              ?
              <ContainerV3 height={challenge ? '315px' : '374px'} width={'100%'} marginTop={1}>
                {monthlyGoal && monthlyGoal.is_goals_set == 0 ?
                  <TabContent radius={1}>
                    <DailyFitnessActivityHeader>
                      <div>
                        <NameContainer>
                          Hey {user.fname}!
                        </NameContainer>
                        <SubTitle>{`Let's Set Your Fitness Goals for ${currentMonth}.`}</SubTitle>
                      </div>
                    </DailyFitnessActivityHeader>
                    {activityLoading ? <LoaderContainer><Loading/></LoaderContainer> :
                      <HighChartContainer>
                        {FitnessGoals.map((data, index) => (
                          <div key={index}>
                            <img src={data.image} />
                            <div>{data.name}</div>
                          </div>
                        ))}
                      </HighChartContainer>
                    }
                    <ButtonContainer>
                      <Button onClick={() => this.showModal('monthlyGoals')}>
                        <img src="/images/NewDashboardV2/FitnessButton.png" />
                        <span>Set Daily Fitness Goals</span>
                      </Button>
                    </ButtonContainer>
                  </TabContent>
                  :
                  monthlyGoal && monthlyGoal.is_goals_set == 1 ?
                    <TabContent radius={1} height={'70%'}>
                      <DailyFitnessActivityHeader height={'30%'} marginBottom={'30px'}>
                        <div>
                          <NameContainer>
                            My {currentMonth}  {`Month Goals`}
                          </NameContainer>
                        </div>
                      </DailyFitnessActivityHeader>
                      {activityLoading ? <LoaderContainer><Loading/></LoaderContainer> :
                        <GridContainer height={'70%'}>
                          <Grid>
                            <div>
                              <img src="/images/NewDashboardV2/personIcon.png" />
                              <PointContainer>{monthlyGoal.monthly_steps}</PointContainer>
                              <div>Steps</div>
                            </div>
                            <div>
                              <img src="/images/NewDashboardV2/calories.png" />
                              <PointContainer>{monthlyGoal.monthly_calories}kCal</PointContainer>
                              <div>Calories</div>
                            </div>
                            <div>
                              <img src="/images/NewDashboardV2/location.png" />
                              <PointContainer>{monthlyGoal.monthly_distance}miles</PointContainer>
                              <div>Distance</div>
                            </div>
                            <div>
                              <img src="/images/NewDashboardV2/timeImg.png" />
                              <PointContainer>70:00 Min</PointContainer>
                              <div>Time</div>
                            </div>
                          </Grid>
                        </GridContainer>
                      }
                    </TabContent>
                    : null
                }
              </ContainerV3>
              : <LoaderContainer><Loading /></LoaderContainer>
            }
          </Tab> */}
        {/* </StyledTabContainer> */}
        <InputGoalsV2
          showModal={modalName === 'monthlyGoals'}
          onHide={this.hideModal}
          SetDailyGoal={SetDailyGoal}
          inputGoals={inputGoals}
          userName={user.fname}
          month={month}
          updatedFitnessGoals={this.updatedFitnessGoals}
        />
      </DailyActivityContainer>
    )
  }

}

DailyFitnessActivityV3.propTypes = {
  SetDailyGoal: PropTypes.func,
  inputGoals: PropTypes.object,
  activityOverview: PropTypes.object,
  activityLoading: PropTypes.bool.isRequired,
  fetchActivityOverview: PropTypes.func.isRequired,
  challenge: PropTypes.number,
  getMonthlyActivityOverview: PropTypes.func,
  monthlyActivityOverview: PropTypes.object,
  user: PropTypes.object,
  month: PropTypes.string,
  monthlyGoal: PropTypes.object,
  check: PropTypes.bool
};

const mapStateToProps = (state) => ({
  activityOverview: state.wellnessDashboard.dailyActivityOverview,
  activityLoading: state.wellnessDashboard.activityLoading,
  monthlyActivityOverview: state.wellnessDashboard.monthlyActivityOverview
});

const mapDispatchToProps = (dispatch) => ({
  fetchActivityOverview: (startDate, endDate) => dispatch(getDailyActivityOverview(startDate, endDate)),
  getMonthlyActivityOverview: (date) => dispatch(getMonthlyActivityOverview(date))

});

export default connect(mapStateToProps, mapDispatchToProps)(DailyFitnessActivityV3);